import React, { ReactElement, useEffect, useState } from 'react';

import { css, SerializedStyles } from '@emotion/react';

import { palette } from '@lib/styles/palette';

import FallBackImg from '@asset/img/fall_back_img.png';

interface SkeletonImageProps {
  src: string | undefined;
  alt: string;
  backgroundColor?: string;
  imgStyle?: SerializedStyles;
  isShowFallback?: boolean;
  fallbackImg?: string;
  [key: string]: any;
}

/**
 * 1. API Fetch 가 아직 완료 되지 않아서 src 가 없는 경우
 * 2. src 는 있지만 image 로드가 완료 되지 않은 경우
 */
export function SkeletonImage({
  src,
  alt,
  backgroundColor = palette.gray0,
  imgStyle,
  onLoad,
  isShowFallback = true,
  fallbackImg = FallBackImg,
  ...props
}: SkeletonImageProps): ReactElement {
  const [isLoading, setIsLoading] = useState(true);
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  const handleImgError = () => {
    setImgSrc(fallbackImg);
  };

  if (!src)
    return (
      <div css={[defaultSkeletonStyle(backgroundColor), imgStyle]} {...props}>
        <div className="animationBar" />
      </div>
    );

  return (
    <>
      {isLoading && (
        <div css={[defaultSkeletonStyle(backgroundColor), imgStyle]} {...props}>
          <div className="animationBar" />
        </div>
      )}
      <img
        src={imgSrc}
        alt={alt}
        css={[defaultImgStyle(isLoading), imgStyle]}
        onLoad={(e) => {
          if (onLoad) onLoad(e);
          setIsLoading(false);
        }}
        onError={handleImgError}
        {...props}
      />
    </>
  );
}

const defaultSkeletonStyle = (backgroundColor: string) => css`
  background-color: ${backgroundColor};
  width: 100%;
  height: 100%;

  @keyframes loading {
    0% {
      transform: translateX(-30%);
    }
    50%,
    100% {
      transform: translateX(100%);
    }
  }

  .animationBar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      ${backgroundColor} 0%,
      #ffffffae 30%,
      ${backgroundColor} 60%
    );
    animation: loading 1.5s infinite linear;
  }
`;

const defaultImgStyle = (isLoading: boolean) => css`
  display: ${isLoading ? 'none' : 'block'} !important;
  width: 100%;
`;
