/* eslint-disable @next/next/no-img-element */
import React, { ReactElement, useEffect, useState } from 'react';

import { checkIsValidResourceUrl, getValidResourceUrl } from '@common/lib';
import { css, SerializedStyles } from '@emotion/react';

import FallBackImg from '@asset/img/fall_back_img.png';

import { SkeletonImage } from '../Skeleton/SkeletonImage';

interface ImageProps {
  src: string;
  alt: string;
  style?: SerializedStyles;
  isSquare?: boolean;
  className?: string;
  imgType?: 'image' | 'wikiImage';
  sizeType?: 'normal' | 'thumbnail';
  dimOpacity?: number;
  fallBackImg?: string;
  priority?: boolean;
}

function ThumbnailImage({
  src,
  alt,
  style,
  isSquare = true,
  className,
  imgType = 'image',
  sizeType = 'normal',
  dimOpacity = 0,
  fallBackImg = FallBackImg,
  priority = false,
}: ImageProps): ReactElement {
  const [showFallbackImg, setShowFallbackImg] = useState(false);

  const getImgSrc = (src: string) =>
    src.startsWith('data:image')
      ? src
      : getValidResourceUrl(src, imgType, sizeType);

  const [imgSrc, setImgSrc] = useState(getImgSrc(src));

  const handleImgError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    // !TODO: any 타입 변경 필요
    setShowFallbackImg(true);
  };

  useEffect(() => {
    if (showFallbackImg) {
      setImgSrc(FallBackImg);
      return;
    }
    setImgSrc(getImgSrc(src));
  }, [showFallbackImg, src]);

  return (
    <div css={imgCard(isSquare, dimOpacity)} className={className}>
      <SkeletonImage
        imgStyle={isSquare ? imgSquare : imgRegtangle}
        src={imgSrc}
        alt={alt}
        referrerPolicy={checkIsValidResourceUrl(src) ? 'no-referrer' : ''}
        onError={handleImgError}
      />
    </div>
  );
}

const imgCard = (isSquare: boolean, dimOpacity: number) => css`
  position: relative;
  width: 100%;
  padding-top: ${isSquare ? '100% ' : ''};
  overflow: hidden;
  border-radius: 4px;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, ${dimOpacity});
    top: 0;
    left: 0;
  }
`;

const imgSquare = css`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  border-radius: 4px;
  object-fit: cover;
  transform: translate(-50%, -50%);
`;
const imgRegtangle = css`
  width: 100%;
  height: 100%;
`;

export default ThumbnailImage;
